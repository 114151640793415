.groupBtn {
  height: 48px;
}
.btn {
  width: fit-content;
  margin-left: 1.5rem;
}
.editBtn {
  width: 115px;
}
.container {
  width: 100%;
  max-width: 736px;
  margin: 0 auto 1rem;
}
.info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  grid-gap: 1.5rem;
  margin-bottom: 1.5rem;
}
.info .updateBy {
  visibility: hidden;
}
.consent {
  margin-bottom: 1rem;
}
.consentContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.checkbox {
  margin-right: 1rem;
}
.consentText {
  color: var(--agt-primary-color);
}
