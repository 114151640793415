.container {
  width: 100%;
  margin: 0 auto;
}
.breadcrumb {
  padding: 2rem 2rem 0;
}
.breadcrumb h4 {
  margin-bottom: 14px;
}
.mainContainer {
  position: relative;
  padding: 0 0 2rem;
  max-width: unset;
}
.logo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 24px;
  object-fit: cover;
}
.display {
  margin: 0 auto;
  width: 100%;
  max-width: 736px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.editbtn {
  position: absolute;
  top: 4rem;
  right: 2rem;
  display: flex;
}
.interview {
  background-color: var(--agt-background-color-1);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 20px 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 14px;
}
.interview small {
  color: var(--agt-secondary-color-1);
}
.newInterview {
  background-color: var(--agt-background-color-1);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}
.newInterview .rowInterview {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
}
.newInterview .rowInterview .lastUpdatedBy {
  color: var(--agt-secondary-color-1);
}
.newInterview .rowInterview .columnInterview {
  width: 48%;
}
.newInterview .rowInterview .columnExplain {
  width: 100%;
  border-bottom: 1px solid var(--agt-secondary-color-1);
}
.newInterview .rowInterview .columnRegisterNote {
  width: 48%;
  border-bottom: 1px solid var(--agt-secondary-color-1);
}
.newInterview .rowInterview .columnRegisterNote .registerNote,
.newInterview .rowInterview .columnExplain .explain {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 6px;
}
.newInterview .authorityHidden {
  display: flex;
  column-gap: 36px;
  opacity: 0;
}
.newInterview .authority {
  display: flex;
  column-gap: 36px;
}
.newInterview .addRole {
  width: 48%;
  align-items: flex-end;
}
.newInterview .authority .authorityLife,
.newInterview .authority .authorityAccident {
  display: flex;
  column-gap: 16px;
  align-items: center;
}
.newInterview .authority .mockupChecked {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: var(--agt-secondary-color-1);
}
.newInterview .authority .mockupChecked .faCheck {
  position: relative;
  /* top: -5px;
  left: 2px;
  font-size: 11px; */
  top: -6px;
  left: 3px;
  font-size: 9px;
  color: var(--agt-white-color);
}
.newInterview .authority .mockupChecked .faCheck {
  position: relative;
  /* top: -5px;
  left: 2px;
  font-size: 11px; */
  top: -6px;
  left: 3px;
  font-size: 9px;
  color: var(--agt-white-color);
}
.newInterview .approved {
  color: var(--agt-success-color);
}
.newInterview .waiting {
  color: var(--agt-primary-color);
}
.newInterview .reject {
  color: var(--agt-error-color);
}
.display .info {
  display: grid;
  row-gap: 24px;
  column-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(7, 1fr); */
  width: 100%;
  margin-bottom: 40px;
}

.display .info .name {
  grid-area: 1 / 1 / 2 / 3;
}
.display .info .gender {
  grid-area: 2 / 1 / 3 / 2;
}
.display .info .birthdate {
  grid-area: 2 / 2 / 3 / 3;
}
.display .info .idCard {
  grid-area: 3 / 1 / 4 / 2;
}
.display .info .phoneNumber {
  grid-area: 3 / 2 / 4 / 3;
}
.display .info .email {
  grid-area: 4 / 1 / 5 / 2;
}
.display .info .homeAddress {
  grid-area: 5 / 1 / 6 / 3;
}
.display .info .idCardAddress {
  grid-area: 6 / 1 / 7 / 3;
}
.display .info .currentAddress {
  grid-area: 7 / 1 / 8 / 3;
}
.display .info .insuranceCompanyName {
  grid-area: 8 / 1 / 9 / 2;
}

.display .info .row {
  width: 100%;
  margin-bottom: 24px;
}

.display .info .column {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.display .info .column .rowHalf {
  width: 48%;
}

.display .info b {
  margin-bottom: 6px;
}

.display .info .values {
  padding-bottom: 6px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
}

.documents {
  border-top: 1px solid var(--agt-secondary-color-1);
}

.documents .agentDocuments {
  padding-top: 40px;
}

.documents .agentImportant,
.documents .agentLicense {
  padding-top: 20px;
}

.documents .agentImportant .header,
.documents .agentDocuments .header {
  display: grid;
  grid-template-columns: 30% 20% 18% auto;
  padding-bottom: 4px;
  color: var(--agt-secondary-color-1);
}

.documents .agentLicense .header {
  display: grid;
  grid-template-columns: 25% 20% 16% 23% auto;
  padding-bottom: 4px;
  color: var(--agt-secondary-color-1);
}
.documents .agentDocuments .notification {
  display: grid;
  grid-template-columns: 20% auto;
  align-items: center;
  padding-right: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
  background: var(--agt-background-color-4);
  margin-bottom: 20px;
}
.documents .agentDocuments .notification .notificationLabel {
  display: flex;
  align-items: center;
  padding-left: 32px;
}
.documents .agentDocuments .notification .notificationLabel .iconInfo svg path {
  fill: var(--agt-primary-color);
}
.documents .agentDocuments .notification label {
  color: var(--agt-primary-color);
  font-weight: bold;
  margin-right: 18px;
}
.documents .agentDocuments .notification .notificationInput {
  margin-bottom: 0;
  color: var(--agt-primary-color);
}
.documents .agentImportant .row,
.documents .agentDocuments .row {
  display: grid;
  grid-template-columns: 30% 20% 18% auto;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
}

.documents .row .name {
  display: flex;
  align-items: center;
}

.documents .row .name label {
  margin-left: 10px;
  text-decoration: underline;
  color: var(--agt-primary-color);
  cursor: pointer;
}

.documents .agentLicense .row {
  display: grid;
  grid-template-columns: 25% 20% 16% 23% auto;
  padding-top: 16px;
  padding-bottom: 24px;
}

.documents .agentLicense .subrow {
  display: grid;
  grid-template-columns: 25% auto;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
  padding-right: 16px;
}

.documents .agentLicense .subrow .description {
  margin-right: 24px;
  color: var(--agt-secondary-color-1);
}

.icon {
  margin-right: 0.5rem;
}

.resendBtn,
.historyBtn {
  width: fit-content;
  margin-right: 1.5rem;
}

.btn > div,
.historyBtn > div {
  display: flex;
}

.iconUpload svg path {
  fill: var(--agt-primary-color);
}
.iconUploadDisabled svg path {
  fill: var(--agt-secondary-color-1);
}
